export const environment = {
  production: false,
  targetAPI: 'https://api.afrikpay.com/api/',
  agentid : '3610387148855918',
  agentpwd : '9e1f6326bc285f84b349c6b3a88dd6ae',
  apikey : '8df09dc02760fd52b21e4b3eaa66bc76',
  url : 'https://creditpopulaire.afrikpay.com',
  id : 'creditpopulaire.afrikpay.com',
  accepturl : 'https://creditpopulaire.afrikpay.com/payment/web/success',
  cancelurl : 'https://creditpopulaire.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};